import React from 'react';
import styled from 'styled-components';

import Section from './section';
import Width from './width';
import Button from './button';

const StyledSection = styled(Section)`
	background: url('https://cdn.iconosquare.com/themes/2018-logged/img/about/about_join_icono_left_mobile.png?v=0.1.009') no-repeat left
		center;
	background-size: 30%;
	height: 400px;

	&::before {
		content: '';
		background: url('https://cdn.iconosquare.com/themes/2018-logged/img/about/about_join_icono_left_mobile.png?v=0.1.009') no-repeat left
			center;
		height: 400px;
		width: 400px;
		position: absolute;
		right: 0;
	}
`;

const GoodCompany = () => (
	<StyledSection>
		<Width className="center">
			<h2>You're in good company</h2>
			<p>Try Radvana for 14 days. No&nbsp;credit&nbsp;card&nbsp;required. Cancel&nbsp;anytime.</p>

			<Button to="/signup/?ref=good-company" className="big">
				Sign up free
			</Button>
		</Width>
	</StyledSection>
);

export default GoodCompany;
