import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { between } from 'polished';
import Img from 'gatsby-image';

import Width from './width';
import Button from './button';

const StyledHeader = styled.header`
	position: relative;
	text-align: center;
	padding-top: ${between('24px', '60px')};
	color: #fff;

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(0, 132, 255, 1) 0%, rgba(0, 158, 255, 1) 100%);
		position: absolute;
		top: 0;
		left: 0;
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), 0 100%);
		z-index: -1;

		@media (min-width: 768px) {
			clip-path: polygon(0 0, 100% 0, 100% calc(100% - 150px), 0 100%);
		}
	}
`;

const Heading = styled.h1`
	font-size: ${between('36px', '66px')};
	line-height: ${between('46px', '80px')};
`;

const Description = styled.p`
	max-width: 940px;
	margin-left: auto;
	margin-right: auto;
	margin-top: ${between('26px', '36px')};
	font-size: ${between('20px', '24px')};
	line-height: ${between('26px', '32px')};

	.not-on-mobile {
		display: none;

		@media (min-width: 768px) {
			display: inline;
		}
	}
`;

const Signup = styled.form`
	margin-top: ${between('26px', '36px')};

	small {
		display: block;
		font-size: ${between('14px', '15px')};
		color: #d4ecff;
		margin-top: ${between('3px', '6px')};
	}
`;

const Input = styled.div`
	position: relative;
	max-width: 480px;
	margin-left: auto;
	margin-right: auto;

	input {
		width: 100%;
		padding: 12px 48px 12px 16px;
		border: none;
		font-size: ${between('15px', '18px')};
		border-radius: 4px;

		&:focus {
			outline: none;
		}
	}

	button {
		background: none;
		border: none;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 0;
		margin-right: 8px;

		span {
			height: 100%;
			display: block;
		}

		.mobile {
			height: 100%;
			font-size: 22px;
			padding: 8px;
		}

		.not-on-mobile {
			display: none;
		}

		&:hover,
		&:focus {
			outline: none;

			.mobile {
				color: rgb(0, 132, 255);
			}
		}
	}

	@media (min-width: 768px) {
		input {
			padding-right: 104px;
		}

		button {
			.mobile {
				display: none;
			}

			.not-on-mobile {
				display: block;
				background: linear-gradient(0deg, rgba(0, 132, 255, 1) 0%, rgba(0, 158, 255, 1) 100%);
				border-radius: 4px;
				color: #fff;
				margin-top: 7px;
				padding: 7px 12px;
				font-size: 16px;
			}
		}
	}
`;

const HeroImage = styled(Img)`
	max-width: 100%;
	height: auto;
	margin-top: ${between('60px', '100px')};
	margin-bottom: -50px;
	user-select: none;
`;

const Header = () => {
	const data = useStaticQuery(graphql`
		query SiteDescriptionQuery {
			site {
				siteMetadata {
					description
				}
			}
			hero: file(relativePath: { eq: "app.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`);

	return (
		<StyledHeader>
			<Width>
				<Heading>Project Management Done&nbsp;Easily</Heading>
				<Description>
					{data.site.siteMetadata.description
						.slice(0, -1)
						.split('.')
						.map((part, i) => {
							return (
								<span key={i} className={i > 0 ? 'not-on-mobile' : ''}>
									{part}.
								</span>
							);
						})}
				</Description>

				<Signup action="/signup/">
					<Input>
						<input type="email" name="email" placeholder="Enter your business email" aria-label="Email" />
						<input type="hidden" name="ref" value="header" />
						<button type="submit" aria-label="Sign up">
							<span className="mobile">→</span>
							<Button className="small not-on-mobile">Sign up</Button>
						</button>
					</Input>
					<small>14-day free trial, no credit card required</small>
				</Signup>

				<HeroImage fluid={data.hero.childImageSharp.fluid} alt="Radvana app" />
			</Width>
		</StyledHeader>
	);
};

export default Header;
