import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { keyframes } from 'styled-components';
import { between } from 'polished';
import { Scrollama, Step } from 'react-scrollama2';

import Section from './section';
import Width from './width';

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const Split = styled(Width)`
	flex-wrap: wrap;
	justify-content: space-between;

	.content {
		flex: 1;
	}

	.big-image {
		display: none;
	}

	@media (min-width: 960px) {
		display: flex;

		.content {
			max-width: 500px;
			min-width: 260px;
		}

		.big-image {
			display: block;
			width: ${between('600px', '800px')};
			height: ${between('300px', '400px', '960px')};
			top: 180px;
			margin-left: ${between('30px', '60px')};
			position: sticky;
			flex: 1;
			text-align: right;

			& > * {
				max-height: 500px;
				max-width: 800px;
				animation: 0.5s ${fadeIn} ease-out forwards;
			}
		}
	}
`;

const StepContent = styled.div`
	padding-top: ${between('22px', '72px', '960px')};
	padding-bottom: ${between('22px', '72px', '960px')};

	&:not(.first) {
		margin-top: ${between('100px', '72px', '960px')};
	}

	&:not(.last) {
		margin-bottom: ${between('100px', '72px', '960px')};
	}

	@media (min-width: 960px) {
		.img {
			display: none;
		}
	}
`;

class Slider extends React.Component {
	state = {
		slide: '',
	};

	onStepEnter = ({ data }) => this.setState({ slide: data });

	render() {
		const { slide } = this.state;

		return (
			<StaticQuery
				query={graphql`
					query {
						planning: file(relativePath: { eq: "planning.png" }) {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
						board: file(relativePath: { eq: "board.png" }) {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
						timeline: file(relativePath: { eq: "timeline.jpg" }) {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				`}
				render={data => (
					<Section>
						<Split>
							<div className="content">
								<Scrollama onStepEnter={this.onStepEnter} offset={0.5}>
									<Step data="planning">
										<StepContent className="first">
											<h2>Planning</h2>
											<p>
												Lorem ipsum dolor sit amet enim. <Link to="/page-2/">Go to</Link> ullamcorper. Suspendisse a pellentesque dui, non felis.
												Maecenas malesuada elit lectus felis, malesuada ultricies.
												<br />
												<br />
												Phasellus fermentum in, dolor. Pellentesque facilisis. Nulla imperdiet sit amet magna.
											</p>

											<Img fluid={data.planning.childImageSharp.fluid} className="img" alt="Radvana planning view" />
										</StepContent>
									</Step>

									<Step data="board">
										<StepContent>
											<h2>Board</h2>
											<p>
												Lorem ipsum dolor sit amet enim. <Link to="/page-2/">Go to</Link> ullamcorper. Suspendisse a pellentesque dui, non felis.
												Maecenas malesuada elit lectus felis, malesuada ultricies.
												<br />
												<br />
												Phasellus fermentum in, dolor. Pellentesque facilisis. Nulla imperdiet sit amet magna.
											</p>

											<Img fluid={data.board.childImageSharp.fluid} className="img" alt="Radvana board view" />
										</StepContent>
									</Step>

									<Step data="timeline">
										<StepContent className="last">
											<h2>Timeline</h2>
											<p>
												Lorem ipsum dolor sit amet enim. <Link to="/page-2/">Go to</Link> ullamcorper. Suspendisse a pellentesque dui, non felis.
												Maecenas malesuada elit lectus felis, malesuada ultricies.
												<br />
												<br />
												Phasellus fermentum in, dolor. Pellentesque facilisis. Nulla imperdiet sit amet magna.
											</p>

											<Img fluid={data.timeline.childImageSharp.fluid} className="img" alt="Radvana timeline view" />
										</StepContent>
									</Step>
								</Scrollama>
							</div>

							<div className="big-image">
								{(() => {
									switch (slide) {
										case 'planning':
											return <Img fluid={data.planning.childImageSharp.fluid} alt="Radvana planning view" />;
										case 'board':
											return <Img fluid={data.board.childImageSharp.fluid} alt="Radvana board view" />;
										case 'timeline':
											return <Img fluid={data.timeline.childImageSharp.fluid} alt="Radvana timeline view" />;
										default:
											return '';
									}
								})()}
							</div>
						</Split>
					</Section>
				)}
			/>
		);
	}
}

export default Slider;
