import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Navigation from '../components/navigation';
import Header from '../components/header';
import Slider from '../components/slider';
import GoodCompany from '../components/good-company';
import Footer from '../components/footer';

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<Navigation blue sticky />
		<Header />
		<Slider />
		<GoodCompany />
		<Footer />
	</Layout>
);

export default IndexPage;
